$(function () {
  "use strict";
  var $accBtn = $(".nintei-btn__next"),
    $accContents = $(".panel");

  $accBtn.each(function () {
    var flag = "close";
    $(this).click(function (e) {
      e.preventDefault();
      $(this)
        .fadeOut(0)
        .next()
        .slideToggle(0);
    });
  });
});
$(function () {
  "use strict";
  $('a[href^="#re"]').click(function () {
    var speed = 500,
      href = $(this).attr("href"),
      target = $(href == "#re" || href == "" ? "html" : href),
      position = target.offset().top;
    $("html, body").animate(
      {
        scrollTop: position
      },
      speed,
      "swing"
    );
    return false;
  });
});

$(window).on("load", function () {
  "use strict";
  var i = 0;
  $(".nintei-detile__tex").each(function () {
    var sHeight = $(".nintei-detile__tex").get(i).scrollHeight,
      oHeight = $(".nintei-detile__tex").get(i).offsetHeight,
      hiddenDiff = sHeight - oHeight;

    if (hiddenDiff > 0) {
      $(this)
        .parent()
        .append(
          '<div class="nintei-more__trigger"><div class="nintei-more__fade"><div class="nintei-btn__more nintei_moreread" >続きを見る<span class="ph-glyphs-btn__plus"><svg version="1.1" id="more_plus" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"><g><path class="plus" d="M288.7,174.6c0,4.1-1.4,7.7-4.3,10.6s-6.4,4.3-10.6,4.3h-85.7v85.7c0,4.1-1.4,7.7-4.3,10.6s-6.4,4.3-10.6,4.3h-47.2c-4.1,0-7.7-1.4-10.6-4.3s-4.3-6.4-4.3-10.6v-85.7H25.6c-4.1,0-7.7-1.4-10.6-4.3s-4.3-6.4-4.3-10.6v-47.2c0-4.1,1.4-7.7,4.3-10.6s6.4-4.3,10.6-4.3h85.7V26.9c0-4.1,1.4-7.7,4.3-10.6S122,12,126.1,12h47.2c4.1,0,7.7,1.4,10.6,4.3s4.3,6.4,4.3,10.6v85.7h85.7c4.1,0,7.7,1.4,10.6,4.3s4.3,6.4,4.3,10.6V174.6z"/></g></svg></span></div></div></div>'
        );
    }
    i++;
  });

  $(".nintei-more__trigger").click(function () {
    var txt_height = parseInt(
      $(this)
        .parent()
        .find(".nintei-detile__tex")
        .css("height"),
      12
    );

    var sHeight = $(this).parent().find(".nintei-detile__tex").get(0).scrollHeight,
      oHeight = $(this).parent().find(".nintei-detile__tex").get(0).offsetHeight,
      hiddenDiff = sHeight - oHeight,
      new_txt_height = txt_height + hiddenDiff;
    $(this)
      .parent()
      .find(".nintei-detile__tex")
      .animate({ height: new_txt_height }, 150);
    $(this).slideUp();
  });
});
