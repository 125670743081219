window.addEventListener('DOMContentLoaded', () => {
  const targetPosition = document.querySelectorAll('.js-article03Target');
  if(targetPosition.length === 0) { return };

  const triggerMargin = 300;
  window.addEventListener('scroll', () => {
    for(i = 0; i < targetPosition.length; i++) {
      if (window.innerHeight > targetPosition[i].getBoundingClientRect().top + triggerMargin) {
        targetPosition[i].classList.add('js-article03View');
      }
    }
  });
});
